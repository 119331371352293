import { reactive, toRefs, useContext, onMounted, computed } from '@nuxtjs/composition-api'
import ClientOAuth2 from 'client-oauth2'
import axios from 'axios'
import { useStorage } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
import dayjs from 'dayjs'
import useFaroIntegration from '~/composables/useFaroIntegration'
import useCustomerData from '~/composables/useCustomerData'
import useUsermanagementApi from '~/composables/useUsermanagementApi'
import useBase64Decoder from '~/composables/useBase64Decoder'
import useRedirectUrl from '~/composables/useRedirectUrl'
import useCurrentLanguage from '~/composables/useCurrentLanguage'
import useSiteCookies from '~/composables/useSiteCookies'

const localStorageToken = useStorage('userToken', null)
const state = reactive({
  fetchLoginInformation: false,
  accessToken: null,
  userIsLoggedIn: null,
  userIsAuthenticated: false,
  tokenData: null,
  tokenDataRoles: [],
  init: true
})

const useLoginInformation = (isLoggedInOnlyPage = false) => {
  const {
    baseUrl,
    clientId,
    authorizationUri,
    registerUri,
    oauthRedirectPath,
    loginStatus,
    tokenUri
  } = useContext().$config

  const { setRedirectUrl } = useRedirectUrl()
  const { currentLanguage } = useCurrentLanguage()
  const redirectUri = `${baseUrl}/${currentLanguage.value}${oauthRedirectPath}`
  const { customerData, customerNumber, setAllCustomerAndCompanyData, clearCustomerData, companyStateIsEmpty } = useCustomerData()
  const { logoutUser } = useUsermanagementApi()
  const { setUserInfoCookies, setAuthCookie, clearCookies } = useSiteCookies()
  const { faroResetUser, faroError } = useFaroIntegration()

  const cookies = useCookies()
  if (!process.client) {
    state.userIsLoggedIn = loginStatus
  }

  onMounted(() => {
    state.userIsLoggedIn = !!cookies.get('Authorization')
    getLocalStorageToken({ force: false })
  })

  const userStateIsReady = computed(() => (state.userIsAuthenticated && !companyStateIsEmpty.value) || false)

  const authData = new ClientOAuth2({
    clientId,
    authorizationUri,
    accessTokenUri: `${tokenUri}/auth/realms/mygundlach/protocol/openid-connect/token`,
    redirectUri,
    query: {
      ui_locales: currentLanguage.value
    }
  })

  async function getLocalStorageToken ({ force = true }) {
    if (!state.fetchLoginInformation && (force || state.init)) {
      state.fetchLoginInformation = true
      if (localStorageToken.value === null) {
        clearDataForLoggedOutUser()
      } else {
        await createRefreshToken()
      }
      state.init = false
    }
  }

  async function createRefreshToken (refresh = false, temporaryToken) {
    if (!state.accessToken || refresh) {
      const token = authData.createToken('', localStorageToken.value || temporaryToken)
      try {
        const newToken = await token.refresh()
        localStorageToken.value = newToken.refreshToken
        state.accessToken = newToken.accessToken
        setAuthCookie(newToken.accessToken)
        await checkLoginState()
        return state
      } catch (err) {
        faroError(err, 'usermanagement-createRefreshToken')
        clearDataForLoggedOutUser()
      }
    } else {
      await checkLoginState()
    }
  }

  function setRefreshTimeout (exp) {
    if (process.client) {
      const now = dayjs().unix()
      const expTimeInMs = (exp - now - 30) * 1000

      setTimeout(() => {
        if (state.userIsAuthenticated) {
          createRefreshToken(true)
        }
      }, expTimeInMs)
    }
  }

  async function checkLoginState () {
    const { decodedBase64 } = useBase64Decoder(state.accessToken)
    if (decodedBase64?.value) {
      state.userIsLoggedIn = true
      state.tokenData = decodedBase64.value
      state.tokenDataRoles = state.tokenData.realm_access?.roles || []
      setAuthHeader()
      state.userIsAuthenticated = true

      setRefreshTimeout(decodedBase64.value.exp)
      // start getting all user data:
      try {
        await setAllCustomerAndCompanyData()
        setUserInfoCookies(state.tokenDataRoles)
        state.fetchLoginInformation = false
        return true
      } catch (err) {
        faroError(err, 'usermanagement-checkLoginState')
        return false
      }
    } else {
      clearDataForLoggedOutUser()
    }
  }

  async function universalLogout () {
    if (state.userIsLoggedIn) {
      try {
        await logoutUser()
        clearDataForLoggedOutUser()
      } catch (err) {
        faroError(err, 'usermanagement-universalLogout')
      }
    }
    window.location.href = `${baseUrl}/${currentLanguage.value}/`
  }

  function clearDataForLoggedOutUser () {
    state.userIsLoggedIn = false
    state.userIsAuthenticated = false
    faroResetUser({
      email: customerData.value.email,
      id: customerNumber.value,
      attributes: {
        message: 'reset-user'
      }
    })
    clearCustomerData()
    state.accessToken = null
    localStorageToken.value = null
    clearCookies()
    if (isLoggedInOnlyPage) {
      redirectUser()
    }
    state.fetchLoginInformation = false
  }

  function redirectUser (slug = `${baseUrl}/${currentLanguage.value}/`, forceRedirect = false) {
    if (!process.client) { return }
    if ((forceRedirect && !state.userIsLoggedIn) || (state.userIsLoggedIn !== null && !state.userIsLoggedIn)) {
      window.location.href = slug
    }
  }

  function setAuthHeader () {
    if (state.accessToken) {
      axios.defaults.headers.common = { Authorization: `Bearer ${state.accessToken}` }
      setAuthCookie(state.accessToken)
    }
  }

  function routeToKcRegister () {
    setRedirectUrl()
    let effectiveRegisterUri = registerUri
    effectiveRegisterUri += `&ui_locales=${currentLanguage.value}`
    effectiveRegisterUri += `&kc_locale=${currentLanguage.value}`
    effectiveRegisterUri += `&redirect_uri=${encodeURIComponent(redirectUri)}`
    window.location = effectiveRegisterUri
  }

  function routeToKcLogin (setRedirectValue = true) {
    if (setRedirectValue) {
      setRedirectUrl()
    }

    if (authData.code) {
      window.location = authData.code.getUri()
    }
  }

  return {
    cookies,
    ...toRefs(state),
    universalLogout,
    setAuthHeader,
    redirectUser,
    clearDataForLoggedOutUser,
    routeToKcLogin,
    routeToKcRegister,
    createRefreshToken,
    userStateIsReady
  }
}
export default useLoginInformation
