import { ref, inject, useContext, reactive, toRefs, computed } from '@nuxtjs/composition-api'

const state = reactive({
  languages: []
})

const useCurrentLanguage = () => {
  const { $cms } = useContext()
  const { defaultLanguageCode } = $cms
  const getLang = inject('getLang', () => 'default')
  const currentLanguage = ref(getLang())
  const { baseUrl } = useContext().$config

  // access meta-data from txp root components
  const defaultFullSlug = inject('getDefaultFullSlug', () => '/')()
  const getTranslatedSlugs = inject('getTranslatedSlugs', () => ['/'])

  // set slug for default language and add slugs from all existing languages
  const translatedSlugs = [
    { lang: defaultLanguageCode, path: defaultFullSlug }
  ]

  const otherTranslatedSlugs = getTranslatedSlugs()
  if (otherTranslatedSlugs?.length) {
    translatedSlugs.push(...otherTranslatedSlugs)
  }

  if (getLang() === 'default') {
    currentLanguage.value = defaultLanguageCode
  } else {
    currentLanguage.value = getLang()
  }

  const currentTranslatedSlug = computed(() => {
    return getTranslatedSlugByLanguage(currentLanguage.value)
  })

  function getTranslatedSlugByLanguage (lang) {
    return translatedSlugs.find(slug => slug.lang === lang)
  }

  function routingUrl (slug) {
    return `/${currentLanguage.value}${slug}`
  }

  state.languages = translatedSlugs.map(slug => slug.lang)

  const baseUrlLang = computed(() => `${baseUrl}/${currentLanguage.value}`)

  return {
    routingUrl,
    currentLanguage,
    defaultLanguageCode,
    translatedSlugs,
    currentTranslatedSlug,
    getTranslatedSlugByLanguage,
    ...toRefs(state),
    baseUrlLang
  }
}
export default useCurrentLanguage
