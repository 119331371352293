import axios from 'axios'

const useUsermanagementApi = () => {
  /* CUSTOMER */
  const customerProxyUrl = '/proxy/usermanagement/customers'
  function updateCustomer (data) {
    return axios.put(customerProxyUrl, data)
  }
  function getCustomerData () {
    return axios.get(customerProxyUrl)
  }
  function deleteCustomer (uuid) {
    return axios.delete(`${customerProxyUrl}/${uuid}`)
  }
  function editCustomerFromCompany (uuid, customer) {
    return axios.put(`${customerProxyUrl}/${uuid}`, customer)
  }
  function inviteCustomer (data) {
    return axios.post(`${customerProxyUrl}/invite`, data)
  }
  function getCustomersFromCompany () {
    return axios.get(`${customerProxyUrl}/company`)
  }
  function deactivateCustomer (uuid) {
    return axios.post(`${customerProxyUrl}/deactivate/${uuid}`)
  }
  function activateCustomer (uuid) {
    return axios.post(`${customerProxyUrl}/activate/${uuid}`)
  }

  /* COMPANY */
  /* ==> Basic company */
  const companyProxyUrl = '/proxy/usermanagement/company'
  function postCombinedCompanyData (formData) {
    return axios.post(
      companyProxyUrl,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }
  function putCombinedCompanyData (formData) {
    return axios.put(
      companyProxyUrl,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }
  function getCompanyData () {
    return axios.get(companyProxyUrl)
  }
  function deleteCompany () {
    return axios.delete(companyProxyUrl)
  }

  /* ==> Files & Logo */
  function getCompanyFiles () {
    return axios.get(`${companyProxyUrl}/files`)
  }
  function getCompanyFile (filename) {
    return axios.get(`${companyProxyUrl}/files/${filename}`, { responseType: 'blob' })
  }
  function deleteCompanyFile (file) {
    return axios.delete(`${companyProxyUrl}/files/${file.filename}`)
  }
  function getCompanyLogo () {
    return axios.get(`${companyProxyUrl}/logo?${Date.now()}`, { responseType: 'blob' })
  }
  function deleteCompanyLogo () {
    return axios.delete(`${companyProxyUrl}/logo`)
  }

  /* ==> Migration */
  function migrateCompany (data) {
    return axios.post(`${companyProxyUrl}/migrate`, data)
  }

  /* Company Settings */
  function getCompanySettingByKey (key) {
    return axios.get(`${companyProxyUrl}/settings/${key}`)
  }

  function setCompanySetting (key, value) {
    return axios.put(`${companyProxyUrl}/settings/${key}/${value}`)
  }

  /* USERINFO */
  function getUserInfo () {
    return axios.get('/proxy/usermanagement/v2/userinfo')
  }

  /* LOGOUT */
  function logoutUser () {
    return axios.get('/proxy/usermanagement/users/logout')
  }

  return {
    updateCustomer,
    getCustomerData,
    getCompanyData,
    getUserInfo,
    getCompanyFiles,
    getCompanyLogo,
    getCompanyFile,
    deleteCompanyFile,
    deleteCompanyLogo,
    deleteCompany,
    deleteCustomer,
    inviteCustomer,
    migrateCompany,
    logoutUser,
    getCustomersFromCompany,
    deactivateCustomer,
    activateCustomer,
    editCustomerFromCompany,
    putCombinedCompanyData,
    postCombinedCompanyData,
    getCompanySettingByKey,
    setCompanySetting
  }
}
export default useUsermanagementApi
