import { reactive, toRefs, computed, ref } from '@nuxtjs/composition-api'
import useFaroIntegration from '~/composables/useFaroIntegration'
import useUsermanagementApi from '~/composables/useUsermanagementApi'

const state = reactive({
  fetchingCustomerData: false,
  customerLogos: [],
  customerFiles: [],
  customerData: {
    firstName: '',
    lastName: '',
    birthDate: null,
    salutation: null,
    email: '',
    uuid: '',
    phone: '',
    state: 'EMPTY'
  },
  companyData: {
    state: null,
    name: '',
    bankData: '',
    phoneLandline: '',
    salesTaxID: '',
    emails: [],
    smallBusiness: false,
    initialSmallBusiness: false,
    address: {
      addition: '',
      city: '',
      countryCode: null,
      street: '',
      zipCode: ''
    },
    erpData: {
      erpId: null,
      errorReason: null,
      active: null
    },
    customerGroup: ''
  },
  userInfo: null
})

const initialized = ref(false)

const useCustomerData = () => {
  const usermanagementApi = useUsermanagementApi()
  const { faroError } = useFaroIntegration()

  function createCompanyFormData (data, files, logo) {
    const formData = new FormData()

    for (const file of files) {
      formData.append('files', file)
    }
    formData.append('logo', logo)
    formData.append('dto', new Blob([JSON.stringify(data)], { type: 'application/json' }))

    return formData
  }

  async function setUserInfoState () {
    try {
      const userInfoReply = await usermanagementApi.getUserInfo()
      state.userInfo = userInfoReply?.data || null
    } catch (err) {
      faroError(err, 'usermanagement-setUserInfoState')
    }
  }

  async function setAllCustomerAndCompanyData () {
    if (!state.fetchingCustomerData) {
      state.fetchingCustomerData = true
      try {
        const replyCustomer = await usermanagementApi.getCustomerData()
        state.customerData = { ...replyCustomer.data }
        const replyCompany = await usermanagementApi.getCompanyData()
        state.companyData = { ...replyCompany.data }
        state.companyData.initialSmallBusiness = replyCompany.data.smallBusiness
        getAllFiles()
        setUserInfoState()
        initialized.value = true
      } catch (err) {
        if (err.response?.status >= 400 && err.response?.status < 500) {
          if (state.customerData.state !== 'EMPTY') {
            state.companyData.state = 'EMPTY'
          }
          return
        }
        if (err.response?.status >= 500) {
          state.companyData.state = null
        }
        faroError(err, 'usermanagement-setAllCustomerAndCompanyData')
      } finally {
        state.fetchingCustomerData = false
      }
    }
  }

  const companyStateIsEmpty = computed(() => {
    return state.companyData.state === null || state.companyData.state === 'EMPTY' || state.companyData.state === ''
  })

  const companyStateNeedsVerification = computed(() => {
    return state.companyData.state === 'NEEDS_VERIFICATION'
  })

  const customerFullName = computed(() => {
    if (state.customerData) {
      return `${state.customerData.firstName} ${state.customerData.lastName}`
    }
    return ''
  })

  const customerNumber = computed(() => {
    if (state.companyData?.erpData?.erpId) {
      return state.companyData.erpData.erpId
    }
    return null
  })

  const customerCountry = computed(() => {
    if (state.companyData?.address?.countryCode) {
      return state.companyData.address.countryCode
    }
    return null
  })

  function getAllFiles () {
    setCompanyFiles()
    setCompanyLogo()
  }

  async function setCompanyFiles () {
    if (state.customerData.role === 'HAUPTNUTZER') {
      try {
        const reply = await usermanagementApi.getCompanyFiles()
        state.customerFiles = reply.data.files
      } catch (err) {
        faroError(err, 'usermanagement-setCompanyFiles')
      }
    }
  }

  async function setCompanyLogo () {
    try {
      const logoReply = await usermanagementApi.getCompanyLogo()
      state.customerLogos = [{ file: logoReply.data }]
    } catch (err) {
      if (err.response.status !== 404) {
        faroError(err, 'usermanagement-setCompanyLogo', false)
      }
      state.customerLogos = []
    }
  }

  async function downloadCustomerFile ({ filename }) {
    try {
      const response = await usermanagementApi.getCompanyFile(filename)
      const blob = new Blob([response.data], { type: response.data.type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (err) {
      faroError(err, 'usermanagement-downloadCustomerFile')
    }
  }

  function clearCustomerData () {
    state.fetchingCustomerData = false
    state.customerLogos = []
    state.customerFiles = []
    state.customerData = {
      firstName: '',
      lastName: '',
      birthDate: null,
      salutation: null,
      email: '',
      uuid: '',
      phone: '',
      state: 'EMPTY'
    }
    state.companyData = {
      name: '',
      bankData: '',
      phoneLandline: '',
      salesTaxID: '',
      smallBusiness: false,
      initialSmallBusiness: false,
      address: {
        addition: '',
        city: '',
        countryCode: null,
        street: '',
        zipCode: ''
      },
      state: null,
      customerGroup: ''
    }
    state.userInfo = null
  }

  return {
    ...toRefs(state),
    customerFullName,
    customerNumber,
    initialized,
    companyStateIsEmpty,
    companyStateNeedsVerification,
    downloadCustomerFile,
    getAllFiles,
    setAllCustomerAndCompanyData,
    clearCustomerData,
    createCompanyFormData,
    customerCountry
  }
}
export default useCustomerData
