import { useRoute, useContext, computed } from '@nuxtjs/composition-api'
import { useStorage } from '@vueuse/core'

const useRedirectUrl = () => {
  const route = useRoute()
  const redirectUrl = useStorage('redirectCallback', null)
  const externalRedirectUrl = useStorage('externalRedirectCallback', null)
  const { $config } = useContext()
  const { baseUrl } = $config

  function setRedirectUrl () {
    redirectUrl.value = `${baseUrl}${route.value.path}`
    if (route.value.query.callback) {
      externalRedirectUrl.value = route.value.query.callback
    }
  }

  function routeToRedirectUrl ({ redirectLink = null, external = false }) {
    let target = baseUrl
    if (external && externalRedirectUrl.value) {
      target = externalRedirectUrl.value
      externalRedirectUrl.value = ''
      redirectUrl.value = ''
    } else if (redirectLink || redirectUrl.value) {
      target = redirectLink || redirectUrl.value
      redirectUrl.value = ''
    }
    if (target.includes('/user/check')) {
      target = '/'
    }
    window.location.replace(target)
  }

  const hasExternalRedirect = computed(() => {
    return !!externalRedirectUrl.value
  })

  return {
    setRedirectUrl,
    routeToRedirectUrl,
    hasExternalRedirect
  }
}

export default useRedirectUrl
