import { useCookies } from '@vueuse/integrations/useCookies'
import { useUniversalCookies } from '~/plugins/universal-cookies.js'
import useCustomerData from '~/composables/useCustomerData'

const localeCookieName = 'locale'

const useSiteCookies = () => {
  const { customerData, companyData } = useCustomerData()

  const setLocaleCookie = (locale) => {
    const cookies = useUniversalCookies([localeCookieName])
    cookies.set(localeCookieName, locale, { path: '/' })
  }

  const getLocaleCookie = (locale) => {
    const cookies = useUniversalCookies([localeCookieName])
    cookies.get(localeCookieName, locale, { path: '/' })
  }

  const setUserInfoCookies = (tokenDataRoles) => {
    const cookies = useCookies()
    const userInfo = {
      companyState: companyData.value.state,
      isCompanyActive: companyData.value.erpData?.active,
      customer: {
        type: customerData.value.role,
        roles: tokenDataRoles
      }
    }
    cookies.set('b4t-userinfo', userInfo, { path: '/' })
  }

  const setAuthCookie = (accessToken) => {
    const cookies = useCookies()
    cookies.set('Authorization', `Bearer ${accessToken}`, { path: '/' })
  }

  const clearCookies = () => {
    const cookies = useCookies()
    cookies.remove('Authorization', { path: '/' })
    cookies.remove('b4t-userinfo', { path: '/' })
  }

  return {
    setLocaleCookie,
    getLocaleCookie,
    setUserInfoCookies,
    setAuthCookie,
    clearCookies
  }
}

export default useSiteCookies
