// import { faro } from '@grafana/faro-web-sdk'
import { ref, onMounted } from '@nuxtjs/composition-api'
const faro = ref(null)

const useFaroIntegration = () => {
  function faroSetUser (payload) {
    if (faro.value) {
      faro.value.api.setUser(payload)
    }
  }
  function faroResetUser (payload) {
    if (faro.value) {
      faro.value.api.resetUser(payload)
    }
  }
  function faroSendEvent (payload) {
    if (faro.value) {
      faro.value.api.pushEvent(payload)
    }
  }

  function faroError (err, type, throwError = true) {
    if (throwError) {
      console.error(err) // eslint-disable-line no-console
    }

    if (faro.value) {
      faro.value.api.pushError(err, {
        type
      })
    }
  }

  onMounted(() => {
    if (process.client) {
      faro.value = window.faro
    }
  })

  return {
    faroSetUser,
    faroResetUser,
    faroSendEvent,
    faroError
  }
}

export default useFaroIntegration
