import { ref } from '@nuxtjs/composition-api'
import { useJwt } from '@vueuse/integrations/useJwt'

const useBase64Decoder = (base64String) => {
  const decodedBase64 = ref(undefined)

  const decodeBase64 = (token) => {
    if (token) {
      const encodedJwt = token
      const { payload } = useJwt(encodedJwt)
      decodedBase64.value = payload.value
    }
  }
  decodeBase64(base64String)

  return {
    decodedBase64
  }
}
export default useBase64Decoder
